import React, { Fragment } from "react";
import jo from "../assets/jo.jpg";
// import ben from "../assets/ben.jpg";
import vithu from "../assets/vithu.jpg";
import niro from "../assets/niro.jpg";
import sniro from "../assets/sniro.jpg";
import sachin from "../assets/sachin.jpg";
import tharun from "../assets/tharun.jpg";
import kiruba from "../assets/kiruba.jpg";
import siva from "../assets/siva.jpg";
import mang from "../assets/mang.jpg";
import nithu from "../assets/nithu.jpg";
import mith from "../assets/mith.png";
import mohan from "../assets/mohan.jpg";
import vino from "../assets/vino.jpg";
import ruth from "../assets/ruht.jpg";
import "./Team.css";
import { Helmet } from "react-helmet-async";
import TeamCard from "../components/TeamCard";

import t001 from "../assets/team/ke-dec.jpg";
import t002 from "../assets/team/keture-back.png";
import t003 from "../assets/team/keture-dev.png";
import t004 from "../assets/team/keture-devops.png";
import t005 from "../assets/team/keture-front.png";
// import t006 from "../assets/team/keture-git.png";
import t007 from "../assets/team/keture-interns.png";
// import t008 from "../assets/team/keture-library.png";
import t009 from "../assets/team/keture-qa.png";
// import t010 from "../assets/team/keture-soc.png";

export default function Team() {
  const teamList = [
    {
      id: 1,
      name: "Josiah Prathaban",
      image: jo,
      designation: "Co-Founder | Team Leader",
      linkedin: "https://www.linkedin.com/in/josiah-prathaban/",
      color: "#26ff76",
      about: (
        <>
          Josiah is a software engineering graduate from the University of
          Kelaniya. His diverse skill set allows him to lead projects
          effectively while contributing creatively to design. Josiah's
          captivating work has earned recognition in multiple communities,
          making him an invaluable asset to our team.
        </>
      ),
    },
    // {
    //   id: 2,
    //   name: "Ben Hanan Subendran",
    //   image: ben,
    //   designation: "Co-Founder | DevOps Head",
    //   linkedin: "https://www.linkedin.com/in/hanancs/",
    //   color: "#cc00ff",
    //   about: (
    //     <>
    //       Ben is a graduate from IIT, Sri Lanka, and currently leads our DevOps
    //       efforts. His passion for creating efficient and reliable systems
    //       drives our team’s success. Ben’s dedication to continuous improvement
    //       and learning makes him an essential part of our team.
    //     </>
    //   ),
    // },
    {
      id: 3,
      name: "Kiruba Karan",
      image: kiruba,
      designation: "Front-End Head",
      linkedin: "https://www.linkedin.com/in/kiruba-karan-a75684179/",
      color: "#ffb570",
      about: (
        <>
          Kiruba is a software engineering graduate from the University of
          Kelaniya who specializes in front-end development. His enthusiasm for
          innovative design and mobile development reflects his passion for
          creating engaging user experiences, making him an important member of
          our team.
        </>
      ),
    },
    {
      id: 4,
      name: "Niroshan Vijayarasha",
      image: niro,
      designation: "Back-End Head",
      linkedin: "https://www.linkedin.com/in/niroshanrajh/",
      color: "#00de5c",
      about: (
        <>
          Niro is a graduate from the University of Kelaniya, focusing on
          backend development. His commitment to empowering others through
          engaging content enhances our team's knowledge and skills. Niro's
          passion for technology makes him a key contributor to our team.
        </>
      ),
    },
    {
      id: 6,
      name: "Sachchin Ram",
      image: sachin,
      designation: "Back-End Engineer",
      linkedin: "https://www.linkedin.com/in/sachchin-ram-06a022182/",
      color: "#00faff",
      about: (
        <>
          Sachchin is a software engineering graduate from the University of
          Kelaniya who is dedicated to backend development. His strong interest
          in software development and problem-solving abilities highlight his
          potential to make a significant impact on our team.
        </>
      ),
    },
    {
      id: 9,
      name: "Sivanujan Sachchithanantham",
      image: siva,
      designation: "Front-End Engineer",
      linkedin: "https://www.linkedin.com/in/ssivanujan1998/",
      color: "#00ff00",
      about: (
        <>
          Siva is a software engineering graduate from the University of
          Kelaniya, focusing on web application development. His commitment to
          creating exceptional software and keen attention to detail underscore
          his importance to our team.
        </>
      ),
    },
    {
      id: 11,
      name: "Rajamiththiran Varatharajan",
      image: mith,
      designation: "Back-End Engineer",
      linkedin: "https://www.linkedin.com/in/raja-miththiran-b6ba671a5/",
      color: "#fff4e3",
      about: (
        <>
          Miththiran is a Software Engineering undergraduate at the University
          of Kelaniya, specializing in backend development. His proactive
          approach and mentorship in our internship program contribute
          significantly to the growth and success of our team.
        </>
      ),
    },
    {
      id: 12,
      name: "Niroshan Pushparaj",
      image: sniro,
      designation: "Front-End Engineer",
      linkedin: "https://www.linkedin.com/in/niroshan-pushparaj/",
      color: "#ffff00",
      about: (
        <>
          Niroshan Pushparaj is a front-end enthusiast and a Software
          Engineering undergraduate at the University of Kelaniya. His passion
          for modern web development and user-friendly interfaces brings fresh
          perspectives and innovation to our projects.
        </>
      ),
    },
    {
      id: 13,
      name: "Mohanatheesan Theiventhiram",
      image: mohan,
      designation: "QA Head",
      linkedin: "https://www.linkedin.com/in/mohan26/",
      color: "#00ffff",
      about: (
        <>
          Mohan, a graduate of the University of Kelaniya, leads our quality
          assurance team with meticulous attention to detail. His commitment to
          maintaining high standards ensures that our products excel, making him
          a key player in our team.
        </>
      ),
    },
    {
      id: 8,
      name: "Ravikrishnan Vithushan",
      image: vithu,
      designation: "QA Engineer",
      linkedin: "https://www.linkedin.com/in/ravikrishnan-vithushan-63a142179/",
      color: "#008cff",
      about: (
        <>
          Vithu, an alumnus of the University of Kelaniya, is a Full Stack
          Engineer who thrives on ensuring quality in every project. His diverse
          interests and commitment to continuous improvement make him an
          invaluable member of our team.
        </>
      ),
    },
    {
      id: 14,
      name: "Vinojan Veerapathirathasan",
      image: vino,
      designation: "UI/UX Head",
      linkedin: "https://www.linkedin.com/in/imvinojanv/",
      color: "#ffffff",
      about: (
        <>
          Vinojan is a Software Engineering undergraduate at the University of
          Kelaniya, specializing in UI/UX design. His passion for creating
          intuitive and engaging user experiences drives his innovative approach
          to design. Vinojan’s creativity and user-centric mindset make him a
          vital member of our team.
        </>
      ),
    },
    {
      id: 15,
      name: "Ruth Hoole",
      image: ruth,
      designation: "Company Secretary",
      linkedin: "https://www.linkedin.com/in/ruth-hoole-b4ba44194/",
      color: "#ffee8c",
      about: (
        <>
          Ruthie is a Mathematics and Computer Science graduate from the University of Ruhuna,
          serving as our company secretary. While primarily focused on
          administrative duties, she is also involved in back-end development,
          making her a valuable member of our team.
        </>
      ),
    },

    {
      id: 7,
      name: "Tharun Varshanth Krishnamoorthy",
      image: tharun,
      designation: "Technical Consultant",
      linkedin: "https://www.linkedin.com/in/tharun-varshanth-krishnamoorthy/",
      color: "#ff004c",
      about: (
        <>
          Tharun is a software engineering graduate from the University of
          Kelaniya who provides valuable insights as a technical consultant. His
          passion for supporting and advising team members makes him a vital
          resource in our tech landscape.
        </>
      ),
    },
    {
      id: 5,
      name: "Mangalan Gnanasegaram",
      image: mang,
      designation: "Cybersecurity Consultant",
      linkedin: "https://www.linkedin.com/in/mangalan-gnanasegaram/",
      color: "#ffc400",
      about: (
        <>
          Mangalan is a Cybersecurity Consultant dedicated to protecting our
          systems and ensuring data integrity. His expertise in identifying and
          managing security vulnerabilities is vital to maintaining a secure
          technology environment, making him an essential asset to our team.
        </>
      ),
    },
    {
      id: 10,
      name: "Nithursan.M",
      image: nithu,
      designation: "Strategy Consultant",
      linkedin: "https://www.linkedin.com/in/nithur/",
      color: "#fbff00",
      about: (
        <>
          Nithursan is a University of Kelaniya alumnus with a degree in
          Software Engineering. His innovative ideas and enthusiasm for data
          science and AI position him as an inspiring figure within our team,
          where he shares valuable insights.
        </>
      ),
    },
  ];

  return (
    <Fragment>
      <Helmet>
        <title>Keture | Our Team</title>
        <meta name="title" content="Keture | Our Team"></meta>
        <meta
          name="description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | Our Team" />
        <meta
          property="og:description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | Our Team" />
        <meta
          property="twitter:description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <div className="bg-img">
      <section className="leading-relaxed max-w-screen-xl pt-12 mx-auto px-4 lg:px-8 pb-20">
        <div className="space-y-3 text-center">
        <h1 className="text-5xl text-gray-300 font-black">Meet Our Team</h1>
          <p className="text-gray-500 max-w-lg mx-auto text-lg">
            Great Engineers. Great Minds.
          </p>
          <ul
          className=" flex justify-center"
        >
          <li>
            <img src={t001} alt="t1" className="object-contain ket" />
          </li>
          <li>
            <img src={t003} alt="t3" className="object-contain ket" />
          </li>
          <li>
            <img src={t004} alt="t4" className="object-contain ket" />
          </li>
          <li>
            <img src={t002} alt="t2" className="object-contain ket" />
          </li>
          <li>
            <img src={t005} alt="t5" className="object-contain ket" />
          </li>
          <li>
            <img src={t009} alt="t9" className="object-contain ket" />
          </li>
          <li>
            <img src={t007} alt="t7" className="object-contain ket" />
          </li>
          {/* <li>
            <img src={t006} alt="t6" className="object-contain ket" />
          </li>
          
          <li>
            <img src={t008} alt="t8" className="object-contain ket" />
          </li>
          
          <li>
            <img src={t010} alt="t10" className="object-contain ket" />
          </li> */}
        </ul>
        </div>
        <div className="mt-14 gap-x-10 sm:grid sm:grid-cols-2 lg:grid-cols-3" >
          {teamList.map((member) => (
            <TeamCard member={member}  />
          ))}
        </div>
      </section>
      </div>
    </Fragment>
  );
}
