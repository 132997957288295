import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";
import ServiceCard from "../components/ServiceCard";
import TestimonialSlider from "../components/TestimonialSlider";

import { Link } from "react-router-dom";
import team from "../assets/team.png";
import "./About.css";

export default function Services() {
  const services = [
    {
      id: 1,
      title: "Code Review Sessions",
      description:
        "Our Code Review Sessions help software teams improve the quality of their code. Our experienced developers provide detailed feedback, identify code issues, and offer recommendations for improvement.",
      benefits: [
        "Improved code quality and performance",
        "Reduced risk of errors and bugs",
        "Enhanced knowledge sharing and team collaboration",
      ],
      keyFeatures: [
        "Comprehensive code review with detailed feedback",
        "Identification of code issues and recommendations",
        "Best practices and optimization techniques",
        "Review of documentation and comments",
      ],
      color: "#5eead4",
      icon: `M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z`,
    },
    {
      id: 2,
      title: "Knowledge Sharing Sessions",
      description:
        "Our Knowledge Sharing Sessions provide software teams with customized training and expertise in various domains and technologies, enhancing their skills and knowledge.",
      benefits: [
        "Improved knowledge and expertise in specific areas",
        "Increased productivity and efficiency",
        "Enhanced team collaboration and communication",
      ],
      keyFeatures: [
        "Customized sessions tailored to your team's needs",
        "Expert speakers with extensive experience",
        "Hands-on training and practical examples",
        "Flexible scheduling and delivery options",
      ],
      color: "#fde047",
      icon: `M208 0c-29.9 0-54.7 20.5-61.8 48.2-.8 0-1.4-.2-2.2-.2-35.3 0-64 28.7-64 64 0 4.8.6 9.5 1.7 14C52.5 138 32 166.6 32 200c0 12.6 3.2 24.3 8.3 34.9C16.3 248.7 0 274.3 0 304c0 33.3 20.4 61.9 49.4 73.9-.9 4.6-1.4 9.3-1.4 14.1 0 39.8 32.2 72 72 72 4.1 0 8.1-.5 12-1.2 9.6 28.5 36.2 49.2 68 49.2 39.8 0 72-32.2 72-72V64c0-35.3-28.7-64-64-64zm368 304c0-29.7-16.3-55.3-40.3-69.1 5.2-10.6 8.3-22.3 8.3-34.9 0-33.4-20.5-62-49.7-74 1-4.5 1.7-9.2 1.7-14 0-35.3-28.7-64-64-64-.8 0-1.5.2-2.2.2C422.7 20.5 397.9 0 368 0c-35.3 0-64 28.6-64 64v376c0 39.8 32.2 72 72 72 31.8 0 58.4-20.7 68-49.2 3.9.7 7.9 1.2 12 1.2 39.8 0 72-32.2 72-72 0-4.8-.5-9.5-1.4-14.1 29-12 49.4-40.6 49.4-73.9z`,
    },
    {
      id: 3,
      title: "Project Kickstart Sessions",
      description:
        "Our Project Kickstart Sessions offer guidance and support to software teams in project planning, technology selection, and knowledge sharing, ensuring successful project initiation.",
      benefits: [
        "Efficient project planning and execution",
        "Reduced risk of project failure",
        "Improved project quality and performance",
      ],
      keyFeatures: [
        "Detailed project planning and scoping",
        "Assistance with technology selection and implementation",
        "Customized knowledge sharing and support",
      ],
      color: "#7dd3fc",
      icon: `M156.6 384.9L125.7 354c-8.5-8.5-11.5-20.8-7.7-32.2c3-8.9 7-20.5 11.8-33.8L24 288c-8.6 0-16.6-4.6-20.9-12.1s-4.2-16.7 .2-24.1l52.5-88.5c13-21.9 36.5-35.3 61.9-35.3l82.3 0c2.4-4 4.8-7.7 7.2-11.3C289.1-4.1 411.1-8.1 483.9 5.3c11.6 2.1 20.6 11.2 22.8 22.8c13.4 72.9 9.3 194.8-111.4 276.7c-3.5 2.4-7.3 4.8-11.3 7.2v82.3c0 25.4-13.4 49-35.3 61.9l-88.5 52.5c-7.4 4.4-16.6 4.5-24.1 .2s-12.1-12.2-12.1-20.9V380.8c-14.1 4.9-26.4 8.9-35.7 11.9c-11.2 3.6-23.4 .5-31.8-7.8zM384 168a40 40 0 1 0 0-80 40 40 0 1 0 0 80z`,
    },
    {
      id: 4,
      title: "Backend Engineering",
      description:
        "Our Backend Engineering services provide software teams with expert assistance in designing, developing, and optimizing robust and scalable backend systems.",
      benefits: [
        "Robust and scalable backend systems",
        "Increased system performance and reliability",
        "Enhanced user experience and satisfaction",
      ],
      keyFeatures: [
        "Custom backend development and optimization",
        "Scalability and performance enhancement",
        "Integration with front-end systems",
        "Monitoring and maintenance",
      ],
      color: "#fca5a5",
      icon: `M308.5 135.3c7.1-6.3 9.9-16.2 6.2-25c-2.3-5.3-4.8-10.5-7.6-15.5L304 89.4c-3-5-6.3-9.9-9.8-14.6c-5.7-7.6-15.7-10.1-24.7-7.1l-28.2 9.3c-10.7-8.8-23-16-36.2-20.9L199 27.1c-1.9-9.3-9.1-16.7-18.5-17.8C173.9 8.4 167.2 8 160.4 8h-.7c-6.8 0-13.5 .4-20.1 1.2c-9.4 1.1-16.6 8.6-18.5 17.8L115 56.1c-13.3 5-25.5 12.1-36.2 20.9L50.5 67.8c-9-3-19-.5-24.7 7.1c-3.5 4.7-6.8 9.6-9.9 14.6l-3 5.3c-2.8 5-5.3 10.2-7.6 15.6c-3.7 8.7-.9 18.6 6.2 25l22.2 19.8C32.6 161.9 32 168.9 32 176s.6 14.1 1.7 20.9L11.5 216.7c-7.1 6.3-9.9 16.2-6.2 25c2.3 5.3 4.8 10.5 7.6 15.6l3 5.2c3 5.1 6.3 9.9 9.9 14.6c5.7 7.6 15.7 10.1 24.7 7.1l28.2-9.3c10.7 8.8 23 16 36.2 20.9l6.1 29.1c1.9 9.3 9.1 16.7 18.5 17.8c6.7 .8 13.5 1.2 20.4 1.2s13.7-.4 20.4-1.2c9.4-1.1 16.6-8.6 18.5-17.8l6.1-29.1c13.3-5 25.5-12.1 36.2-20.9l28.2 9.3c9 3 19 .5 24.7-7.1c3.5-4.7 6.8-9.5 9.8-14.6l3.1-5.4c2.8-5 5.3-10.2 7.6-15.5c3.7-8.7 .9-18.6-6.2-25l-22.2-19.8c1.1-6.8 1.7-13.8 1.7-20.9s-.6-14.1-1.7-20.9l22.2-19.8zM112 176a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zM504.7 500.5c6.3 7.1 16.2 9.9 25 6.2c5.3-2.3 10.5-4.8 15.5-7.6l5.4-3.1c5-3 9.9-6.3 14.6-9.8c7.6-5.7 10.1-15.7 7.1-24.7l-9.3-28.2c8.8-10.7 16-23 20.9-36.2l29.1-6.1c9.3-1.9 16.7-9.1 17.8-18.5c.8-6.7 1.2-13.5 1.2-20.4s-.4-13.7-1.2-20.4c-1.1-9.4-8.6-16.6-17.8-18.5L583.9 307c-5-13.3-12.1-25.5-20.9-36.2l9.3-28.2c3-9 .5-19-7.1-24.7c-4.7-3.5-9.6-6.8-14.6-9.9l-5.3-3c-5-2.8-10.2-5.3-15.6-7.6c-8.7-3.7-18.6-.9-25 6.2l-19.8 22.2c-6.8-1.1-13.8-1.7-20.9-1.7s-14.1 .6-20.9 1.7l-19.8-22.2c-6.3-7.1-16.2-9.9-25-6.2c-5.3 2.3-10.5 4.8-15.6 7.6l-5.2 3c-5.1 3-9.9 6.3-14.6 9.9c-7.6 5.7-10.1 15.7-7.1 24.7l9.3 28.2c-8.8 10.7-16 23-20.9 36.2L315.1 313c-9.3 1.9-16.7 9.1-17.8 18.5c-.8 6.7-1.2 13.5-1.2 20.4s.4 13.7 1.2 20.4c1.1 9.4 8.6 16.6 17.8 18.5l29.1 6.1c5 13.3 12.1 25.5 20.9 36.2l-9.3 28.2c-3 9-.5 19 7.1 24.7c4.7 3.5 9.5 6.8 14.6 9.8l5.4 3.1c5 2.8 10.2 5.3 15.5 7.6c8.7 3.7 18.6 .9 25-6.2l19.8-22.2c6.8 1.1 13.8 1.7 20.9 1.7s14.1-.6 20.9-1.7l19.8 22.2zM464 304a48 48 0 1 1 0 96 48 48 0 1 1 0-96z`,
    },
    {
      id: 5,
      title: "DevOps Engineering",
      description:
        "Our DevOps Engineering services help software teams streamline their development and deployment processes through continuous integration, automation, and efficient infrastructure management.",
      benefits: [
        "Streamlined software development and deployment",
        "Faster time-to-market",
        "Increased reliability and system performance",
      ],
      keyFeatures: [
        "Continuous integration and deployment",
        "Infrastructure automation",
        "Automated testing",
        "Monitoring and optimization",
      ],
      color: "#c084fc",
      icon: `M290.8 48.6l78.4 29.7L288 109.5 206.8 78.3l78.4-29.7c1.8-.7 3.8-.7 5.7 0zM136 92.5V204.7c-1.3 .4-2.6 .8-3.9 1.3l-96 36.4C14.4 250.6 0 271.5 0 294.7V413.9c0 22.2 13.1 42.3 33.5 51.3l96 42.2c14.4 6.3 30.7 6.3 45.1 0L288 457.5l113.5 49.9c14.4 6.3 30.7 6.3 45.1 0l96-42.2c20.3-8.9 33.5-29.1 33.5-51.3V294.7c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-1.3-.5-2.6-.9-3.9-1.3V92.5c0-23.3-14.4-44.1-36.1-52.4l-96-36.4c-12.8-4.8-26.9-4.8-39.7 0l-96 36.4C150.4 48.4 136 69.3 136 92.5zM392 210.6l-82.4 31.2V152.6L392 121v89.6zM154.8 250.9l78.4 29.7L152 311.7 70.8 280.6l78.4-29.7c1.8-.7 3.8-.7 5.7 0zm18.8 204.4V354.8L256 323.2v95.9l-82.4 36.2zM421.2 250.9c1.8-.7 3.8-.7 5.7 0l78.4 29.7L424 311.7l-81.2-31.1 78.4-29.7zM523.2 421.2l-77.6 34.1V354.8L528 323.2v90.7c0 3.2-1.9 6-4.8 7.3z`,
    },
    {
      id: 6,
      title: "System Design",
      description:
        "Our System Design services assist software teams in designing and architecting complex software systems, ensuring scalability, reliability, and performance.",
      benefits: [
        "Well-designed and scalable software systems",
        "Fault-tolerant and high-performance architectures",
        "Enhanced user experience and satisfaction",
      ],
      keyFeatures: [
        "Customized system design based on project requirements",
        "Expertise in various system architectures and technologies",
        "Ongoing support and maintenance",
      ],
      color: "#34d399",
      icon: `M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z`,
    },
    {
      id: 7,
      title: "Cloud Engineering",
      description:
        "Our Cloud Engineering services empower software teams to design, deploy, and manage applications on cloud infrastructure, enabling scalability, flexibility, and cost efficiency.",
      benefits: [
        "Scalable and elastic cloud-based solutions",
        "Improved resource utilization and cost optimization",
        "High availability and disaster recovery",
      ],
      keyFeatures: [
        "Cloud architecture design and implementation",
        "Migration and deployment to cloud platforms",
        "Optimization of cloud resources",
        "Monitoring, security, and performance optimization",
      ],
      color: "#fb923c",
      icon: `M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128H144zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39V392c0 13.3 10.7 24 24 24s24-10.7 24-24V257.9l39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z`,
    },
  ];

  return (
    <Fragment>
      <Helmet>
        <title>Keture | Services</title>
        <meta name="title" content="Keture | Services"></meta>
        <meta
          name="description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | Services" />
        <meta
          property="og:description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | Services" />
        <meta
          property="twitter:description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <div className="mx-auto space-y-2 bg-gradient-to-r from-blue-900 to-sky-500 p-10">
        <div className="max-w-6xl mx-auto text-center">
          <h4 className="text-5xl text-gray-200 font-black my-5">
            Software Team Services - Keture
          </h4>

          <p className="text-gray-300 text-xl max-w-5xl mb-5 mx-auto">
            At Keture, we offer a range of specialized services to support
            software teams in their development projects. From code review and
            knowledge sharing sessions to backend engineering, devops, and
            system design, our team of experts is here to help yours. Our
            services are designed to help software teams overcome challenges,
            improve their skills, and enhance their productivity. Discover how
            we can help your team today.
          </p>
        </div>
      </div>
      <div className="bg-img">
      <section className="leading-relaxed mx-auto px-4 lg:px-8 pb-20 pt-5 max-w-screen-xl">
        <div className="space-y-3 text-center">
          {/* <h1 className="text-5xl text-gray-400 font-black">
            Our Services
          </h1> */}
          {services.map((service) => (
            <ServiceCard service={service} key={service.id} />
          ))}

          
        </div>
        <div className="space-y-3 text-center mt-20">
        <TestimonialSlider></TestimonialSlider>
        </div>
      </section>
      </div>
      <div className="mx-auto bg-gradient-to-r from-blue-900 to-sky-500 pt-10 overflow-x-clip">
        <div className="max-w-6xl mx-auto text-center px-5 relative z-10">
          <h4 className="text-2xl text-gray-200 font-black my-5 md:text-4xl">
            Discover how we can assist your software team! Fill out our Software
            Team Inquiry Form to share your needs with us and unlock tailored
            solutions.
          </h4>

          <Link to="/team-inquiry">
            <button className="bg-gray-100 hover:bg-gray-300 text-sky-800 font-bold py-3 px-5 rounded text-sm md:text-lg">
              Fill out the Inquiry Form ⟶
            </button>
          </Link>
        </div>
        <img src={team} alt="team" className="team-img" />
      </div>
    </Fragment>
  );
}
