import React, { useState } from "react";
import "./Comp.css";

export default function TeamCard(props) {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return (
    <>
      <div
        className="space-y-2 my-5 rounded-md bg-white/5 p-5 ring-1 ring-white/10 opacity-90 isolate overflow-hidden relative"
        key={props.member.id}
      >
        <div className="object-center lg:w-full  relative">
          {/* <img
            loading="lazy"
            src={props.member.image}
            alt={props.member.name}
            className="w-80 rounded-full"
          /> */}
          <div className="relative">
            {isLoading && (
              <div className="animate-pulse opacity-50 bg-white/5 w-80 h-80 rounded-full isolate overflow-hidden relative">
                <svg
                  className="absolute top-0 left-1/2 -z-10 h-[42.375rem] -translate-x-1/2 blur-3xl xl:-top-6"
                  viewBox="0 0 1155 678"
                  fill="none"
                >
                  <path
                    fill={`url(#${props.member.id + "lg"})`}
                    fillOpacity="1"
                    d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                  />
                  <defs>
                    <linearGradient
                      id={props.member.id + "lg"}
                      x1="1155.49"
                      x2="-78.208"
                      y1=".177"
                      y2="474.645"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor={props.member.color} />
                      <stop offset={1} stopColor={props.member.color} />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            )}
            <img
              src={props.member.image}
              alt={props.member.name}
              className={`w-80 rounded-full ${
                isLoading ? "opacity-0" : "opacity-100"
              }`}
              onLoad={handleImageLoad}
            />
          </div>

          <a
            href={props.member.linkedin}
            rel="noreferrer"
            target="_blank"
            className="text-gray-300 linkedin rounded-full flex items-center place-content-center place-items-center"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="linkedin-in"
              className="w-6"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
              />
            </svg>
          </a>
        </div>
        <h4 className="text-xl text-gray-200 font-bold">{props.member.name}</h4>
        <h5 className="text-lg text-gray-300 font-medium">
          {props.member.designation}
        </h5>
        <p className="text-gray-400">{props.member.about}</p>
        <svg
          className="absolute top-0 left-1/2 -z-10 h-[42.375rem] -translate-x-1/2 blur-3xl xl:-top-6"
          viewBox="0 0 1155 678"
          fill="none"
        >
          <path
            fill={`url(#${props.member.id})`}
            fillOpacity=".1"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id={props.member.id}
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={props.member.color} />
              <stop offset={1} stopColor={props.member.color} />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </>
  );
}
