import React, { Fragment } from "react";
import "./About.css";
import { Helmet } from "react-helmet-async";

export default function Careers() {
  return (
    <Fragment>
      <Helmet>
        <title>Keture | Careers</title>
        <meta name="title" content="Keture | About"></meta>
        <meta
          name="description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | About" />
        <meta
          property="og:description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | About" />
        <meta
          property="twitter:description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <section className="leading-relaxed mx-auto px-4 lg:px-8 bg-img py-20">
        <div className="space-y-3 text-center">
          <h1 className="text-5xl text-gray-400 font-black">
            At the moment, we're not hiring. Please check back often for new
            career opporunities.
          </h1>
        </div>
      </section>
    </Fragment>
  );
}
