import React, { useState } from "react";
import { MessengerChat } from "react-messenger-chat-plugin";
import "./Comp.css";

export default function Footer() {
  const [currentYear] = useState(() => new Date().getFullYear());
  return (
    <>
      <MessengerChat
        pageId="100774412572414"
      // language="sv_SE"
      // themeColor={"#000000"}
      // bottomSpacing={300}
      // loggedInGreeting="loggedInGreeting"
      // loggedOutGreeting="loggedOutGreeting"
      // greetingDialogDisplay={"show"}
      // debugMode={true}
      // onMessengerShow={() => {
      //   console.log("onMessengerShow");
      // }}
      // onMessengerHide={() => {
      //   console.log("onMessengerHide");
      // }}
      // onMessengerDialogShow={() => {
      //   console.log("onMessengerDialogShow");
      // }}
      // onMessengerDialogHide={() => {
      //   console.log("onMessengerDialogHide");
      // }}
      // onMessengerMounted={() => {
      //   console.log("onMessengerMounted");
      // }}
      // onMessengerLoad={() => {
      //   console.log("onMessengerLoad");
      // }}
      />
      <footer className="text-center lg:text-left bg-gray-800 text-gray-300 opacity-95 relative isolate overflow-hidden">
        <div className="flex justify-center items-center lg:justify-between p-6 border-y border-gray-700">
          <div className="mr-12 hidden lg:block text-gray-600">
            <span>Get connected with us on social networks —</span>
          </div>
          <div className="flex justify-center">
            <a
              href="https://www.facebook.com/keturecom"
              rel="noreferrer"
              target="_blank"
              className="mr-6 text-gray-300 rounded-md bg-white/5 p-2 ring-1 ring-white/10 opacity-90 social-link"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="facebook-f"
                className="w-2.5"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
              >
                <path
                  fill="currentColor"
                  d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                ></path>
              </svg>
            </a>
            <a
              href="https://twitter.com/keturecom"
              rel="noreferrer"
              target="_blank"
              className="mr-6 text-gray-300 rounded-md bg-white/5 p-2 ring-1 ring-white/10 opacity-90 social-link"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="twitter"
                className="w-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.youtube.com/channel/UCbG4fO68ZKsnihf-73ZF1_g"
              rel="noreferrer"
              target="_blank"
              className="mr-6 text-gray-300 rounded-md bg-white/5 p-2 ring-1 ring-white/10 opacity-90 social-link"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="youtube"
                className="w-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.instagram.com/keturecom/"
              rel="noreferrer"
              target="_blank"
              className="mr-6 text-gray-300 rounded-md bg-white/5 p-2 ring-1 ring-white/10 opacity-90 social-link"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="instagram"
                className="w-3.5"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                ></path>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/keture"
              rel="noreferrer"
              target="_blank"
              className="mr-6 text-gray-300 rounded-md bg-white/5 p-2 ring-1 ring-white/10 opacity-90 social-link"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="linkedin-in"
                className="w-3.5"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path
                  fill="currentColor"
                  d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                ></path>
              </svg>
            </a>
            <a
              href="https://github.com/Keture"
              rel="noreferrer"
              target="_blank"
              className="text-gray-300 rounded-md bg-white/5 p-2 ring-1 ring-white/10 opacity-90 social-link"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="github"
                className="w-4"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 496 512"
              >
                <path
                  fill="currentColor"
                  d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                ></path>
              </svg>
            </a>
          </div>
        </div>
        <div className="mx-6 py-10 text-left">
          <div className="grid grid-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            <div className="">
              <h5
                className="
            font-black
            mb-4
            flex
            items-center
            text-xl bg-gradient-to-r from-orange-400 via-red-600 to-red-600 text-transparent bg-clip-text
          "
              >
                Keture (Pvt) Ltd.
              </h5>
              <h5
                className="
            font-thin
            mb-1
            flex 
            items-center 
            text-lg
          "
              >
                Vision
              </h5>
              <p className="font-extralight mb-3">
                We're here to say "Yes! We Can"
              </p>
              <h5
                className="
            font-thin
            mb-1
            flex 
            items-center 
            text-lg
          "
              >
                Mission
              </h5>
              <p className="font-extralight">
                To provide a supportive platform for individuals seeking to advance their careers in the IT industry
                and to develop world-class software that empowers businesses of all sizes.
              </p>
            </div>
            <div className="grid grid-2 grid-cols-2 lg:grid-cols-2 gap-8">
              <div className="">
                <h6 className="font-extrabold mb-4 flex">Community</h6>
                <p className="mb-4">
                  <a
                    href="https://github.com/Keture"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-400"
                  >
                    GitHub
                  </a>
                </p>
                <p className="mb-4">
                  <a
                    href="https://stackoverflow.com/c/keture"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-400"
                  >
                    Stack Overflow
                  </a>
                </p>
                <p className="mb-4">
                  <a
                    href="https://groups.google.com/g/keture-discussion-forum"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-400"
                  >
                    Disussion Forum
                  </a>
                </p>
                <p className="mb-4">
                  <a
                    href="https://trello.com/keture"
                    rel="noreferrer"
                    target="_blank"
                    className="text-gray-400"
                  >
                    Trello
                  </a>
                </p>
                <p>
                  <a
                    href="https://discord.gg/MgHH8qYS"
                    className="text-gray-400"
                  >
                    Discord
                  </a>
                </p>
              </div>
              <div className="">
                <h6 className="font-bold mb-4 flex">Quick Links</h6>
                <p className="mb-4">
                  <a href="/privacy-policy" className="font-extralight">
                    Privacy Policy
                  </a>
                </p>
                <p className="mb-4">
                  <a href="/terms-and-conditions" className="font-extralight">
                    Terms & Conditions
                  </a>
                </p>
                <p className="mb-4">
                  <a href="/careers" className="font-extralight">
                    Careers
                  </a>
                </p>
                <p className="mb-4">
                  {/* <a href="https://keture.medium.com/" className="font-extralight"> */}
                  <a href="https://blog.keture.com" className="font-extralight">
                    Blog
                  </a>
                </p>
                <p>
                  <a href="/faq" className="font-extralight">
                    FAQ
                  </a>
                </p>
              </div>
            </div>

            <div className="">
              <h6 className="font-bold mb-4 flex">Contact</h6>
              <a
                href="https://www.google.com/maps/dir//keture/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x3afdd9a679e51ca1:0xb75a950eaf8c286b?sa=X&ved=2ahUKEwitrcu0nqb-AhU52TgGHU3FBEAQ9Rd6BAg9EAU"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-start mb-4 font-extralight"
              >
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10 mr-4 opacity-90 social-link">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="home"
                    className="w-4"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                  >
                    <path
                      fill="currentColor"
                      d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
                    ></path>
                  </svg>
                </div>
                No. 61/2, Chavakattu, Mannar, Sri Lanka.
              </a>
              <a
                href="mailto:hello@keture.com"
                className="flex items-center mb-4 font-extralight"
              >
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10 mr-4 opacity-90 social-link">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="envelope"
                    className="w-4 "
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"
                    ></path>
                  </svg>
                </div>
                hello@keture.com
              </a>
              <a
                href="tel:+94766641296"
                className="flex items-center mb-4 font-extralight"
              >
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10 mr-4 opacity-90 social-link">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="phone"
                    className="w-4"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                    ></path>
                  </svg>
                </div>
                + 94 76 664 1296
              </a>
              <a
                href="tel:+94768716424"
                className="flex items-center mb-4 font-extralight"
              >
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10 mr-4 opacity-90 social-link">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="phone"
                    className="w-4"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                    ></path>
                  </svg>
                </div>
                + 94 76 871 6424
              </a>
              {/* <a
                href="tel:+1234567890"
                className="flex items-center mb-4 font-extralight"
              >
                <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10 mr-4 opacity-90 social-link">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="phone"
                    className="w-4"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"
                    ></path>
                  </svg>
                </div>
                + 94 768 856 485
              </a> */}
            </div>
          </div>
        </div>
        <div className="text-center p-6 bg-gray-900 opacity-50">
          <span>Copyright © </span>
          <a className="text-4 font-semibold bg-gradient-to-r from-orange-400 to-red-600 inline-block text-transparent bg-clip-text opacity-100" href="https://keture.com">
            Keture
          </a>
          <span> {currentYear} | All Rights Reserved.</span>
        </div>
        <svg
          className="absolute top-0 left-1/2 -z-10 h-[42.375rem] -translate-x-1/2 blur-3xl xl:-top-6"
          viewBox="0 0 1155 678"
          fill="none"
        >
          <path
            fill="url(#09dbde42-e95c-4b47-a4d6-0c523c2fca9a)"
            fillOpacity=".1"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="09dbde42-e95c-4b47-a4d6-0c523c2fca9a"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#f97316" />
              <stop offset={1} stopColor="#fbbf24" />
            </linearGradient>
          </defs>
        </svg>
      </footer>
    </>
  );
}
