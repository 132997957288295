import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyCBW1NAXhmuBWz7C4ISviTl9M0lgilL_zg",
  authDomain: "keture-web.firebaseapp.com",
  projectId: "keture-web",
  storageBucket: "keture-web.appspot.com",
  messagingSenderId: "226094943973",
  appId: "1:226094943973:web:16647908b2bbb82afd4e0c",
  measurementId: "G-MYRWEZZQMP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log(analytics);

ReactDOM.render(
  <BrowserRouter>
    <Router />
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals(console.log);
