import React, { Fragment } from "react";
import "./About.css";
import { Helmet } from "react-helmet-async";
import Trees from "../components/Trees";

export default function Xmas() {
  return (
    <Fragment>
      <Helmet>
        <title>Keture | X-mas</title>
        <meta name="title" content="Keture | About"></meta>
        <meta
          name="description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | About" />
        <meta
          property="og:description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | About" />
        <meta
          property="twitter:description"
          content="Our cutting-edge technology and personalized approach help our clients streamline operations, increase productivity, and drive growth. Discover how Keture can transform your business today."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <section className="leading-relaxed mx-auto px-4 lg:px-8 py-10 sm:py-20 bg-img">
        <div className="space-y-3 text-center">
          <div className="flex justify-end">
            <div className="rounded-full bg-white/5 p-2 ring-1 ring-white/10 mr-4 opacity-90 cursor-pointer absolute top-28 social-link">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="sound"
                className="w-10 h-10 p-2"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M499.1 6.3c8.1 6 12.9 15.6 12.9 25.7l0 72 0 264c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6L448 147 192 223.8 192 432c0 44.2-43 80-96 80s-96-35.8-96-80s43-80 96-80c11.2 0 22 1.6 32 4.6L128 200l0-72c0-14.1 9.3-26.6 22.8-30.7l320-96c9.7-2.9 20.2-1.1 28.3 5z"
                />
              </svg>
            </div>
          </div>
          <Trees />

          <h1 className="text-4xl sm:text-5xl text-gray-300 font-black pt-4 glow-text">
            Merry Christmas!
          </h1>
          <p className="text-gray-200 max-w-lg mx-auto sm:text-lg">
            This season, we celebrate the greatest gift:
            <span className="text-gray-400 max-w-lg mx-auto sm:text-lg italic">
              “But God shows his love for us in that while we were still
              sinners, Christ died for us.” – Romans 5:8
            </span>
          </p>

          <p className="sm:text-lg">
            Warm wishes from the{" "}
            <span className="text-lg sm:text-xl font-black bg-gradient-to-r from-yellow-500 via-orange-500 to-red-500 text-transparent bg-clip-text">
              Keture
            </span>{" "}
            team.
          </p>
        </div>
      </section>
    </Fragment>
  );
}
