import "./Comp.css";
import React, { useState } from "react";

export default function ServiceCard(props) {
  // https://heroicons.dev/?iconset=v2-20-solid icon site

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpand = () => {
    setIsExpanded(true);
  };

  const handleCollapse = () => {
    setIsExpanded(false);
  };

  return (
    <>
      <div
        className="max-w-5xl mx-auto space-y-2 my-5 rounded-md bg-gray-800 p-5 border-2 border-gray-700"
        key={props.service.id}
      >
        <h4
          className="text-3xl font-black text-left flex"
          // style={{ color: props.service.color }}
        >
          {props.service.title}

          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fab"
            className="w-10 h-10 ml-3 flex-shrink-0"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
          >
            <path fill={props.service.color} d={props.service.icon}></path>
          </svg>
        </h4>

        <p className="text-gray-300 text-left text-lg">
          {props.service.description}
        </p>

        {isExpanded && (
          <div className="px-5 py-5">
            <h2 className="mb-2 text-lg font-semibold text-white text-left">
              Benefits:
            </h2>
            <ul className="space-y-1 text-gray-400 list-inside px-1">
              {props.service.benefits.map((benefit, key) => (
                <li className="flex items-start" key={key}>
                  <svg
                    className="w-4 h-4 mr-1.5 mt-1.5 text-green-400  flex-shrink-0"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <div className="text-left">
                  {benefit}
                  </div>
                  
                </li>
              ))}
            </ul>

            <h2 className="mt-5 mb-2 text-lg font-semibold text-white text-left">
              Key features:
            </h2>
            <ul className="space-y-1 text-gray-400 list-inside px-1">
              {props.service.keyFeatures.map((feature, key) => (
                <li className="flex" key={key}>
                  <svg
                    className="w-4 h-4 mr-1.5 mt-1.5 flex-shrink-0 "
                    fill={props.service.color}
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 1a6 6 0 00-3.815 10.631C7.237 12.5 8 13.443 8 14.456v.644a.75.75 0 00.572.729 6.016 6.016 0 002.856 0A.75.75 0 0012 15.1v-.644c0-1.013.762-1.957 1.815-2.825A6 6 0 0010 1zM8.863 17.414a.75.75 0 00-.226 1.483 9.066 9.066 0 002.726 0 .75.75 0 00-.226-1.483 7.553 7.553 0 01-2.274 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <div className="text-left">
                  {feature}
                  </div>
                  
                </li>
              ))}
            </ul>
          </div>
        )}

        {isExpanded && (
          <button
            onClick={handleCollapse}
            className="flex items-start font-medium
              text-gray-400 flex-shrink-0 hover:text-gray-200"
          >
            Show less
            <svg
              className="w-6 h-6 ml-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                fillRule="evenodd"
                d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
              ></path>
            </svg>
          </button>
        )}

        {!isExpanded && (
          <button
            onClick={handleExpand}
            className="flex items-start font-medium
              text-gray-400 flex-shrink-0 hover:text-gray-200"
          >
            Learn more
            <svg
              className="w-6 h-6 ml-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        )}
      </div>
    </>
  );
}
