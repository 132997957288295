import React, { Fragment } from "react";
import { Helmet } from "react-helmet-async";

export default function Services() {
  return (
    <Fragment>
      <Helmet>
        <title>Keture | Services</title>
        <meta name="title" content="Keture | Services"></meta>
        <meta
          name="description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | Services" />
        <meta
          property="og:description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | Services" />
        <meta
          property="twitter:description"
          content="Meet the talented and dedicated team behind Keture. Our diverse group of professionals brings a wealth of experience and expertise to every project, ensuring exceptional results and client satisfaction."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <div className="mx-auto bg-green-50 pt-10">
        <iframe
          title="keture-form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdL9c-2JP_BfqoTpqrpT7CWJE0_VEs5osNk9CPSbQZ_9-pbaA/viewform?embedded=true"
          width="100%"
          height="1200"
          frameborder="0"
          marginheight="0"
          marginwidth="0"
        >
          Loading…
        </iframe>
      </div>
    </Fragment>
  );
}
