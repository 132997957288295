import React, { useEffect } from 'react';
import "./Tree.css";

const Trees = ({ treesToDraw = 12 }) => {
  useEffect(() => {
    // Create an audio element for the background music
    const audio = new Audio('./xmas.mp3'); // Replace with the path to your audio file

    // Play the audio when the component mounts
    audio.play().catch((error) => {
      console.warn("Audio playback failed: ", error);
    });

    // Function to toggle play/pause on click
    const toggleAudioPlayPause = () => {
      if (audio.paused) {
          audio.play().catch(() => {}); // Play if paused
      } else {
          audio.pause(); // Pause if playing
      }
  };

    // Define functions to handle focus and blur
    // const handleFocus = () => audio.play().catch(() => {}); // Resume playing on focus
    const handleBlur = () => audio.pause(); // Pause when window loses focus

    // Add focus and blur event listeners
    // window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);
    window.addEventListener('click', toggleAudioPlayPause);
    window.addEventListener('touchstart', toggleAudioPlayPause);

    // Cleanup: stop audio on unmount, remove event listeners
    return () => {
      audio.pause(); // Stop the audio when the component unmounts
      // window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
      window.removeEventListener('click', toggleAudioPlayPause);
      window.removeEventListener('touchstart', toggleAudioPlayPause);
    };
  }, []);

  return (
    <div className="container">
      {[...Array(treesToDraw)].map((_, index) => (
        <svg
          key={index}
          className="tree"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 83 218"
        >
          <defs>
            <filter id="glow">
              <feGaussianBlur in="SourceGraphic" result="coloredBlur" stdDeviation="4" />
              <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <path
            d="M83 218H66v-28l-6-1-47-1c-14 0-16-3-8-15l22-34 3-4-9-1c-9-1-11-5-7-12l25-36 10-16c2-4 3-6-2-9s-2-6 0-9l22-34L83 0"
            style={{ filter: "url(#glow)" }}
          />
        </svg>
      ))}
    </div>
  );
};

export default Trees;
