import React, { Fragment, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import "./About.css";

const FaqsCard = (props) => {
  const answerElRef = useRef();
  const [state, setState] = useState(false);
  const [answerH, setAnswerH] = useState("0px");
  const { faqsList, idx } = props;

  const handleOpenAnswer = () => {
    const answerElH = answerElRef.current.childNodes[0].offsetHeight;
    setState(!state);
    setAnswerH(`${answerElH + 20}px`);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Keture | FAQ</title>
        <meta name="title" content="Keture | FAQ"></meta>
        <meta
          name="description"
          content="Keture, a software company based in Mannar, is an up-and-coming small entity to impact the community with a small workforce unlike big tech giants around the world."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://keture.com/" />
        <meta property="og:title" content="Keture | FAQ" />
        <meta
          property="og:description"
          content="Keture, a software company based in Mannar, is an up-and-coming small entity to impact the community with a small workforce unlike big tech giants around the world."
        />
        <meta
          property="og:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />

        {/* Twitter  */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://keture.com/" />
        <meta property="twitter:title" content="Keture | FAQ" />
        <meta
          property="twitter:description"
          content="Keture, a software company based in Mannar, is an up-and-coming small entity to impact the community with a small workforce unlike big tech giants around the world."
        />
        <meta
          property="twitter:image"
          content="https://raw.githubusercontent.com/Keture/Keture-Logos/main/metabg.png"
        />
      </Helmet>
      <div
        className="space-y-3 mt-5 overflow-hidden border-b"
        key={idx}
        onClick={handleOpenAnswer}
      >
        <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-gray-300 font-medium">
          {faqsList.q}
          {state ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500 ml-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20 12H4"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-gray-500 ml-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 4v16m8-8H4"
              />
            </svg>
          )}
        </h4>
        <div
          ref={answerElRef}
          className="duration-300"
          style={state ? { height: answerH } : { height: "0px" }}
        >
          <div>
            <p className="text-gray-500">{faqsList.a}</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default function FAQ() {
  const faqsList = [
    {
      q: "What can you help me with?",
      a: (
        <>
          With whatever we can. Preparing cost analysis, establishing team
          roles, creating roadmaps. Defining business goals, key business
          values, target users, main features, prioritising. We can find you
          similar projects that are already active on the market to benchmark
          against. We can assist you with creating user personas, mock-ups, user
          stories, time frame, establishing project status and preparing project
          estimation. We'll be happy to outline project specification, prepare
          wireframes, details concerning integrations with external services,
          API documentation, required features list. In terms of project
          development we prepare server-side architecture, front end, back end,
          graphic and UX design, and create back-office consoles. We are also
          happy to advise our customers in terms of budgeting, scheduling, risk
          management, and business model creation.
        </>
      ),
    },
    {
      q: "How do I create a product with you?",
      a: (
        <>
          If you have an idea contact us by Facebook Chat Plugin(found in the
          bottom left corner of our site) or E-mail or Phone. We'll meet and
          talk it over. Just be sure to prepare as much info about your idea as
          possible, it will smoothen the meeting and benefit further
          cooperation.{" "}
        </>
      ),
    },
    {
      q: "Should I create a mobile or a web app?",
      a: (
        <>
          Both have their benefits and flaws. Mobile apps are surely more
          expensive but can provide you with many more data collecting,
          monetisation capabilities than web applications. Progressive Web Apps
          are a good compromise between a mobile and web app. But remember that
          sooner or later you may need both mobile and web app and when that
          moment comes we are here for you
        </>
      ),
    },
    {
      q: "Do I need to be tech-savvy to cooperate with you and order a software application?",
      a: (
        <>
          You certainly do not have to be a software development expert, that's
          what we're here for. We welcome your experience in that matter if you
          happen to have any, but are prepared to guide you through the whole
          process and explain everything. Focus on your needs and we'll deliver
          what you require.
        </>
      ),
    },
    {
      q: "Can the software be upgraded in the future as new technology becomes available?",
      a: (
        <>
          Of course! We are always happy to improve, upgrade and further develop
          our work.
        </>
      ),
    },
    {
      q: "What if I come to you with a product that I want to improve?",
      a: (
        <>
          We'll be more than happy to help you with that. Beginning with a UX
          Audit (if applicable) through improving your system architecture and
          adding new features.
        </>
      ),
    },
    {
      q: "How much do you charge?",
      a: (
        <>
          It depends on many things: project scope, time spent on the project
          and technological issues. We know it sounds vague but its simply
          honest. Remember that each software project is different, especially
          if you had a particularly original idea. We do not want to give you an
          unrealistic estimate, instead we want to provide you with the best
          quality possible within your budget.{" "}
        </>
      ),
    },
  ];

  return (
    <section className="leading-relaxed mx-auto px-4 lg:px-8 bg-img py-20">
      <div className="space-y-3 text-center">
        <h1 className="text-3xl text-gray-400 font-semibold">
          Frequently Asked Questions
        </h1>
        <p className="text-gray-600 max-w-lg mx-auto text-lg">
          Answered all frequently asked questions, Still confused? feel free to
          contact us.
        </p>
      </div>
      <div className="mt-14 max-w-2xl mx-auto">
        {faqsList.map((item, idx) => (
          <FaqsCard idx={idx} faqsList={item} />
        ))}
      </div>
    </section>
  );
}
